<template>
  <div class="container mescroll-touch">
    <img class="img" v-for="(item, index) in PdfList" :key="index" :src="item.File_Url" @click="imagePreview(item.File_Url)" alt="" srcset="">
    <div class="bottombar">
      <div class="bottombar_item" @click="$router.go(-1)">
        <img src="@/assets/images/back.png">
        <span>返回</span>
      </div>
    </div>
  </div>
</template>

<script>
import { ImagePreview } from 'vant';
export default {
  name: 'ProductDetails',
  data() {
    return {
      PdfList: []
    }
  },
  computed: {
    id() {
      return this.$route.query.id;
    }
  },
  created() {
    this.getPdf();
  },
  methods: {
    getPdf() {
      this.$axios.get('/Api/api/Web/article/GetArtPDF?id=' + this.id).then(res => {
        this.PdfList = res.Data;
      })
    },
    imagePreview(url) {
      ImagePreview({
        images: [url],
        maxZoom: 10,
        showIndex: false,
        loop: false,
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding-bottom: 60px;
  .img {
    display: block;
    width: 100%;
  }
  .bottombar {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    height: 60px;
    background-color: #FFFFFF;
    .bottombar_item {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 25%;
      font-weight: bold;
      font-size: 0.9em;
      img {
        width: 22px;
        margin-bottom: 4px;
      }
    }
  }
}
</style>